import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { CurrentUser } from "./Helpers/CurrentUser";
import { Layout } from "./Pages/Layout/Layout";
import { Login } from "./Pages/Login/Login";
import { Municipalities } from "./Pages/Municipalities/Municipalities";
import { Register } from "./Pages/Register/Register";
import { ForgottenPassword } from "./Pages/ForgottenPassword/ForgottenPassword";
import { SessionViewer } from "./Pages/SessionViewer/SessionViewer";
import { Sessions } from "./Pages/Sessions/Sessions";
import { SharedSessionViewer } from "./Pages/SharedSessionViewer/SharedSessionViewer";
import { ResetPassword } from "./Pages/ResetPassword/ResetPassword";

function App() {

  const userToken = CurrentUser.getToken();
  console.log("user token", userToken);

  if (!userToken) {
    return (
      <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgottenpassword" element={<ForgottenPassword />} />
        <Route path="sharedSession" element={<SharedSessionViewer />} />
        <Route path="reset/*" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<SessionViewer />} />
        <Route path="/" element={<Layout />}>
          <Route path="sessions" element={<Sessions />} />
          <Route path="municipalities" element={<Municipalities />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="sharedSession" element={<SharedSessionViewer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
